import { Skeleton, Stack } from '@mui/joy';

export const PageHeaderLoadingSkeleton = () => (
  <header>
    <Stack
      pt={3}
      mb={{ xs: 4, md: 2 }}
      spacing={2}
      direction={'row'}
      justifyContent={'space-between'}
    >
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
      >
        <Skeleton variant={'rectangular'} width={64} height={64} />
        <Skeleton variant={'rectangular'} width={{ xs: 36, md: 124 }} height={36} />
      </Stack>
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
      >
        <Skeleton variant={'rectangular'} width={{ xs: 36, md: 79 }} height={{ xs: 36, md: 24 }} />
        <Skeleton variant={'rectangular'} width={{ xs: 36, md: 87 }} height={36} />
        <Skeleton variant={'rectangular'} width={{ xs: 75, md: 220 }} height={36} />
      </Stack>
    </Stack>
  </header>
);
